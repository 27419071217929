import React from "react";

const Military = () => {
  return (
    <div className="py-[70px]">
      <div className="w-full h-px bg-zinc-800"></div>
      <div className="py-12 bg-majorbg">
        <h1 className="text-center text-white text-[40px] font-normal font-['URWImperialW01-Regular'] capitalize leading-[50px]">
          Military Career
        </h1>
      </div>

      <div className="bg-gray-100 lg:mx-[4%] mx-2 text-justify px-2 lg:px-8">
        <div className="flex justify-center items-center py-4">
          <h1 className="text-3xl  border-b-[4px] border-black py-2 font-extrabold">
            The World War -II
          </h1>
        </div>

        <div className="font-bold text-2xl w-44 my-4 ">
          <h1 className="border-b-2 border-black relative py-1"> Joining of IPC</h1>
        </div>

        <div className="ml-8 py-4">
          <p>
            {" "}
            When the dark shadows of World War 2 were looming over the Indian
            Subcontinent Major Ghani was serving in the Bengal Fire Brigade in
            Kolkata. He was the first Muslim Officer to join the service in
            1939. Racing around Kolkata in fire engines was not something that
            he loved when the war was raging across the border with Burma. He
            responded to the call for military service and joined the emergency
            commission service offered by the then Colonial British Indian Army.
            He joined the Officers’ Training School in Bangalore and started his
            rigorous training from 1st January 1942. By then the Japanese were
            overrunning the Burmese peninsula and laid their plans to invade
            India. Under these war time condition he received his commission in
            the Corps of Infantry on 8th August 1942.
          </p>
        </div>

        <div>
          <p>
            It needs to be mentioned here that to meet the war time forces needs
            the government was hastily raising emergency forces to support the
            main fighting forces. That said being commissioned in Infantry was a
            feat for a Bangali speaking individual as there were other services
            where he could be posted like Services units not exposed to direct
            front line fighting. But for the then Lt Ghani it was the Indian
            Pioneer Corps raised out of the population who could just hold a
            shovel or a pick to build bridge, air strips or roads or dig bunkers
            for the fighting soldiers. Lt Ghani’s qulalities proved to be the
            need of the time where leadership and organisational capabilities
            were paramount importance to control the half trained Pioneer Corps
            Soldiers. In him they found the man who could be trusted to be
            followed and the commanders found him to be the leader of men. So
            was his war record. He was assigned in various appointment in the
            front east of Brahmaputra river and the Supreme Command Allied
            Forces Command forces headquarters.
          </p>
        </div>

        <div>
          <p>
            need to highlight the role of the Pioneer corps briefly for
            understanding. Indian Pioneer Corps may be called a franchise of
            Royal Pioneer Corps who fought in the European wars and were in some
            cases retained permanently. But in the case of Indian Subcontinent
            the Pioneer corps was only raised just as the threat of invasion
            developed from the East. So who so ever had physical fitness to work
            was recruited regardless of other attributes needed to make a
            regular fighting soldier.
          </p>
        </div>
        <div>
          <p>
            They were deemed to provide labour force to build roads, bridges,
            airfields, bunkers etc to help the regular soldiers fight the
            invading fierce life defying enemies. They also formed the major
            part of the auxiliary force. However, if emergency arose, they were
            also given weapons to defend themselves. War changes demographics
            the history and national boundaries. That's what was happening
            during that period in the Indian Subcontinent. On one side there was
            famine brought by the war in 1943 where the British Prime minister
            the so-called hero but villain on this part of the world diverted
            most of the resources for war mongering leaving the civil population
            die of starvation. The other blatant fact emerged that the two major
            religious groups of the Subcontinent could not live together. The
            fact that the Bengali Muslims lived in the delta of Ganges River
            collecting the filths coming from the upstream. Bangalees were not
            liked by the other ethnic groups of Bihar, Punjab, Baluch and many
            more who called never considered them a fighting or a maritial race.{" "}
          </p>
        </div>
        {/* <div>
          <h1>Against Enemies - From East of Brahmaputra River to Akyab.</h1>
          <p>
            The map of the battle ground from 1942 1945 shows how the Japanese
            pushed into India and how they were pushed by b the 14th Army of
            General Slim. General Messarvey was well present in the campaign and
            it was very natural that they met in due course of the battle as the
            then Capt Ghani moved from north to south with his Pioneer Group of
            Support soldiers to support the battle.
            <span>War Servicre</span>
            <span>Events Engagement</span>
            <span>
              Date of Commission in the corps of Infantry 8th August 1942
            </span>
            <span>
              East of Brahmaputra River 24th April 1943 to 3rd March 1945
            </span>
            <span>
              SEAC (Southeast Asian Command) Akyab 4th March 1945 to 15th July
              1946
            </span>
          </p>
        </div>

        <div>
          <img src="" alt="" />
        </div>

        <div>
          <h1>
            {" "}
            The Concept of forming a regular fighting infantry unit for East
            Bengal
          </h1>
          <p>
            Under this back drop of looming ominous socio political changes Capt
            Ghani who was assigned to fight East of Brahmaputra river to Akyab
            saw the brutalities of war for over three years and the impending
            changes to shape his mind. His deep awareness of the prevailing
            socio-political condition and the fallout from the impending
            partition of India could only lead to one thought that the
            partitioned east Bengal should have a military presence as a regular
            force in the national army. If the partition did not materialise
            then he was fully determined to have a Bengal Regiment in Indian
            Army. Even in the late of 1946 it remained unclear how the partition
            will materialise in the midst of the inhuman extermination of humans
            through communal riots. These hastened the process of the partition
            and even after the partition millions were slaughtered and moved
            across borders to find new home of co religionists
          </p>
        </div>

        <div>
          <h1>The Elements</h1>
          <p>
            So immediately after the cessation of hostilities the Pioneer Corps
            combatants were reporting to their various centres for processing
            for demobilisation. It was at that point the then Capt Ghani,
            Adjutant of Pioneer corps in Jalna, India found the best opportunity
            to impregnate the idea of a separate regular fighting unit out of
            the Pioneer Corps soldiers. He has his ‘ Band of Brothers’ to fulfil
            his dream. Meanwhile with the partition round the corner the British
            government was in a hurry to help reorganise the divided national
            army. The C in C of Pakistan Army was appointed a General Mesarvey
            who was deeply engaged in the Burma Campaign initially with his
            brigade later with his as Major General. During these critical
            periods of war all the elements including all the supporting forces
            joined hands to push the enemy back. No wonder that the then Capt A
            Ghani being either as Quartermaster or Adjutant or as Company
            Commander of the Auxiliary Indian Pioneer Corps came in close
            contact with the Divisional commander. And a person like Ghani who
            outsmarted most of the fighters in the area definitely attracted the
            attention of the divisional staff and the commander himself. As
            narrated by Late Major General R A M Golam Mukhtadir in an informal
            occasion mentioned, ‘ Taj your dad used to share tea breaks with
            General Messerevey during the Burma Campaign,’. Although General
            Mukatadir did not participate in WW 2 but the soldiers of Indian
            Pioneer Corps would tell the tales to the war to their officers
            after they were formed into East Bengal Regiment.
          </p>
          <p>
            Before the partition of the Indian Subcontinent the forces were
            leaving the front line and returning to their barracks and when the
            dates of the partition was announced the Chiefs of various
            establishments in both the countries were announced. General Sir
            Douglas Messervey was appointed as the Chief of the Pakistan Army.
            Capt Ghani was then in Jalna and organising his selected soldiers to
            be moved to the then East Pakistan. Captain Ghani on receipt of the
            news of the appointment of the C in C of Pakistan Army sent a DO or
            Demi Official latter, expressing his desire that the Pioneer Corps
            soldiers returning to East Pakistan be converted into a fighting
            unit or infantry unit as there were no such unit from East Pakistan
            or East Bengal. General Messrrvy endorsing the hopes of Capt Ghani
            replied that he was in full agreement with the formation of an
            Infantry unit out of the Pioneer Corps soldiers who have been
            recommended for retention in the post war India.{" "}
          </p>
          <p>
            There are sceptics to think that how such a decision could be made
            on a DO. Even the traditional Pakistan hierarchy was taken aback
            with the decision as forming a fighting unit out of the Pioneer
            Corps soldiers were not deliberated in the General Headquarters of
            Pakistan immediately after the partition. General Messervey saw the
            tenacity of the Pioneer corps soldiers during the Burma Campaign and
            did not even wink his eye to reply to the suggestion of Captain
            Ghani about the formation of the Infantry Regiment. Without losing
            any opportunity Capt Ghani customarily congratulated the appointed C
            in C and highlighted the need for a regular fighting infantry unit
            from the would be East Pakistan. He further mentioned the pioneer
            corps who fought under him in the war provided the ready manpower
            who were war veterans. On reply to his letter the General gave his
            approval that an infantry unit would be raised out of the Pioneer
            Corps soldiers. It was the break that he was waiting for. His Centre
            Commandant a Baluch Officer encouraged him to select the correct
            collection of personnel who could be effectively absorbed in an
            Infantry Battalion. Well rest is the history.{" "}
          </p>
          <p>
            The first East Bengal Regiment was raised at Kurmitola on 15
            February 1948 and many more followed in subsequent years. The birth
            of East Bengal Regiment is the result of Major A Gani's foresight,
            efforts, perseverance and love for Bengalis and motherland. It will
            be noteworthy to note the remarks of a Commanding Officer of 1st
            East Bengal Regiment in 1950 which pens down some out of many
            qualities of Major A Ghani. It says: I have the pleasure to put in
            record all that I know o captain A. Gani who has been in close touch
            with me for a couple of years. He is a young man of towering
            personality combining in himself all the laudable qualities of head
            and heart. He has tremendous dash and drive & wonderful
            administrative and organizing capacity. A born leader, a marvellous
            speaker, he can control and command respect from all alike. He is
            very popular and widely known figure in this part of the country,
            especially for his being Asst. Recruiting Officer in which capacity
            he has had opportunities and coming in contact with high officials,
            leading public and the masses. His suave & jovial manner, amiable
            and pleasant behaviour endear him to all.
          </p>
          <p>
            He is very hard-working, painstaking, responsible & reliable. It was
            due to his initiating capacity coupled with his genuine patriotic
            feeling s and sincere devotion and loyalty to his State the East
            Bengal Regiment was brought into being and which is the nucleus of
            the Bengali Army in Pakistan. A man of varied experiences in the
            Army, I am sure, he will be and acquisition on any Branch of the
            Civil Administration where Organization, Administrative &
            Controlling abilities are needed most. Founder of The East Bengal
            Regiment - Bangladesh Army When the when the war ended in 1945 the
            Pioneer corps soldiers were sure to be sent home - they were only
            recruited temporarily to fill in the gap of shortage of labour force
            needed to beef up the battlefield. I need to highlight the role of
            the Pioneer corps briefly for understanding. Indian Pioneer Corps
            may be called a franchise of Royal Pioneer Corps who fought in the
            European wars and were in some cases retained permanently. But in
            the case of Indian Subcontinent the Pioneer corps was only raised
            just as the threat of invasion developed from the East. So who so
            ever had physical fitness to work was recruited regardless of other
            attributes needed to make a regular fighting soldier. His deep
            awareness of the prevailing socio political condition and the
            fallout from the impending partition of India could only lead to one
            thought that the partitioned east Bengal should have a military
            presence as a regular force in the national army.... Let me be made
            wise by anyone who could give me the name of any individual who had
            similar thought and continuous war experience and trained force to
            marshal a force to show the hierarchy that such force existed and
            only to be recognised beyond any prejudice of race and border. to
            educate me more on the field. The first East Bengal Regiment was
            raised at Kurmitola on 15 February 1948 and many more followed in
            subsequent years. The birth of East Bengal Regiment is the result of
            Major A Gani's foresight, efforts, perseverance and love for
            Bengalis and motherland
          </p>
        </div>

        <div>
          <h1>Defender of his mother tongue</h1>
          <p>
            That in spite of his great feat of organising a fighting unit for a
            country why he earned their wreath and had to be medically invalided
            out of army after a span of only 5 years of active service since
            1948. It happened when he showed his real open conflict of interest
            with the ruling Pakistan authority by raising his voice on the day
            of raising of 1st East Bengal Regiment on 15th February 1948. When
            the local area commander the then Brig Ayub Khan articulated, ‘From
            now on the Bengali soldiers will speak in Urdu’. This enraged the
            young Captain Ghani who retorted, ‘ We Bengali soldiers shall not
            speak in any language other than Bangali, our mother tongue’.
            Following is the extracts taken from a book Late Major M T Hossain
            about the incident that took place on 15th February 1948. The Area
            Commander shouted in front oh the distinguished gathering, ‘Shut up
            sit down’. This was a taboo to speak in the face of a superior that
            too in the Army on such instruction. Never the less he was promoted
            to the rank of Local Major on the same day to the utter surprise and
            elation of his band of brothers the Pioneers who regarded him as
            their father figure. He was appointed as training company commander
            and responsible for recruitment.
          </p>
        </div>

        <div>
          <h1>Expansion of E-Bengal</h1>
          <p>
            Immediately after the formation of the 1st East Bengal Regiment
            there was a further screening of personnel to fit into the Infantry
            fighting unit features and most of the Pioneer Corps Soldiers were
            young and filled in the vacancies of combatants. The 2nd E Bengal
            Regiment was raised in February 1949 with skimming man power from
            the 1st E Bengal so there was shortage of man and the gaps must be
            filled up as soon as possible. There was shortage of instructors and
            seniors to form the leadership channel. So fast promotion was given
            to the competent soldiers. As trainers came the Non Commissioned
            Officers from other fighting units from West Pakistan and as usual
            there was language issues but things got sorted out evenly
          </p>
        </div>
        <div>
          <h2>Recruitment the youth </h2>
          <p>
            He moved heaven and earth to find the most able bodied youths for
            the East Bengal units as there was shortage to be filled up before
            the authority decides to fill up the vacancies with soldiers from
            other regiments of Pakistan Army. So he was racing against time.
            Tirelessly he moved from one corner of the country to the other to
            motivate youths and the younger generation to join the military to
            serve the nation. It was a movement that he started to convince that
            Bengali youths could be better soldiers than other Pakistani
            soldiers. It was definitely a movement to prove that the than East
            Pakistan has to have its own defence forces to defend its borders
          </p>
        </div>
        <div>
          <h1>The Conflicts of Interest</h1>
          <p>
            All seemed to be going well for newly promoted Major A Ghani. He was
            made Temporary Major on 15th May 1948 and posted out of the unit on
            23rd May 1948 to Pakistan National Guard (PNG) as Adjutant to be
            held by a captain, so he loses his rank of Major. It came as deep
            shock to the whole unit and the soldiers protested, which, whoever
            is not recorded but related by the ex soldiers. They were in deep
            sorrow to see their father figure who they followed for so many
            years in the battle field so humiliated. Well for the than Capt
            Ghani it was a challenge to control his emotion in the face of
            retribution from the authority. He never wanted that any of his
            troops to suffer due to this unfortunate incident. He calmly
            explained that PNG was another unit of Pakistan Army to be raised so
            he is wanted there. That his departure should not have any effect in
            the functioning of the unit. For the authority will try to find a
            pretext to shed as many soldiers as possible to make the existence
            of the unit questionable. He was totally frustrated and
            disillusioned with the attitude of the Pakistani military hierarchy,
            who gave him three times the rank of Major and craftily got him at
            the lower rank of Captain by transferring him from one unit to. the
            other in such capacities. More over the aggressive moves to recruit
            soldiers for the E Bengal units left a toll on his body. But his
            deep attachment with the regiment only kept his spirit alive with
            the hope that some day he will see his comrads at arms who backed
            him up to found the regiment. But the authority was adamant not to
            allow him around and kept him away so that the soldiers would not be
            influenced by him in any way.
          </p>
        </div>
        <div>
          <h1>Departure from his Regiment</h1>
          <p>
            Continued illness owing to the exposure during the periods of
            recruitment had taken a toll on his body and mind. But he was not
            some one to give up hope. He had a clear understanding that the
            authority would not ever give him any opportunity to serve in the
            regiment so there was no point to suffer through their step
            brotherly attitude. Due to chronic ill ness he was medically
            invalided out of army in December 1953.
          </p>
        </div>
        <div>
          <h2>Denial by the authority</h2>
          <p>
            Even his hard work was not good enough to convince that he was
            better than any of the Pakistani officers who were serving as
            commissioned officer. However, his inherent spirit of integrity and
            freedom was not some quality that the Pakistani military hierarchy
            liked so he suffered with repeated promotions and demotions. But it
            did not break his spirit. He decided to take a different approach to
            serve his people.{" "}
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default Military;
