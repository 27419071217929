import React from 'react'
import homebig from '../../assets/image/homedfdf.png'
import homebig2 from '../../assets/image/home3.png'
import homebig3 from '../../assets/image/home4.png'

const Birth = () => {
  return (
    <div className="py-[70px]">
         <div class="w-full h-px bg-zinc-800"></div>
     <div className='py-12 bg-majorbg'>
    


     <h1 class="text-center text-white text-[40px] font-normal font-['URWImperialW01-Regular'] capitalize leading-[50px]">Birth of a Warrior</h1>

    </div>   


    <div className='mx-4 flex justify-center text-justify p-4 items-center mt-4 pb-16'>
       <div>
      <div className='flex justify-center items-center pb-8'>
      <img src={homebig} alt="" className='w-[1000px] h-[400px]' />
      </div>
       <div className='md:mx-16 2xl:mx-72 xl-44  mx-4 text-justify'>
       <p class=" pt-5 text-neutral-600 text-xl font-normal font-['Inter'] leading-relaxed">On the brisk morning of Sunday, December 1st, 1919, Abdul Ghani was born into the 'Poju' clan in Borobari, a village within Nagaish, under the Brmanpara Upazila of Comilla district, encapsulating a life set against the backdrop of rural simplicity and the daunting challenges of an era devoid of modern conveniences. This environment, pristine yet harsh, lacked the amenities of contemporary life but was rich in the freedom to shape one's destiny, mirroring the unfettered essence of nature itself. Ghani's early years were intertwined with this natural setting, offering him the liberty and relentless trials that honed his innate character strengths</p>
       <div className='flex justify-center items-center py-8'>
          <img src={homebig2} className='w-[1000px] h-[400px]' alt="" />
        </div>
        
        <p class="pt-5 text-neutral-600 text-xl font-normal font-['Inter'] leading-relaxed">His name, Ghani, symbolizing prosperity, self-sufficiency, and independence as referenced in the Quran, foretold the impactful life he was to lead. Despite the convention that would have his surname as Ali, after his father Maulana Sharafat Ali, he was aptly named Ghani, embodying the qualities that would define his legacy. Ghani's resilience was tested early on, with the profound loss of his mother at the tender age of two and a half, a tragedy that deeply affected his father, who was initially resistant to the idea of remarriage. Yielding to the pressures of family for the sake of his son's well-being, his father eventually remarried</p>
        <div className='flex justify-center items-center py-8'>
          <img src={homebig3} className='w-[1000px] h-[400px]' alt="" />
        </div>
        <p class=" pt-5 text-neutral-600 text-xl font-normal font-['Inter'] leading-relaxed">Though the sole child and cherished son of his father, Ghani found a surrogate family in his uncle's home, where he formed close bonds with cousins of similar age. His upbringing was significantly influenced by his uncles, affluent landlords, who immersed him in the richness of rural life, filled with stories and experiences that shaped him. This setting, devoid of modern comforts yet abundant in natural freedom and the constant struggle for betterment, forged Ghani's inherent qualities of leadership and resilience.</p>
        <p class=" pt-5 text-neutral-600 text-xl  font-normal font-['Inter'] leading-relaxed">As his child, and the second among five siblings, our experiences with him were limited; he was more of a guiding presence than a constant physical one, dedicating his life to serving those in need and championing the causes he believed in. Within the constraints of this narrative, it is a challenge to fully articulate his vast contributions. Nevertheless, it is essential to recognize his role as a warrior, the visionary founder of a distinguished infantry regiment, and a politician dedicated to the principles of good governance. These roles underscore his lasting impact on the fabric of our nation, showcasing his remarkable journey from a child nurtured by nature's unfettered freedom and challenges to a pivotal figure in our country's history.</p>

       </div>
       </div>
    </div>
    
    
       </div>
  )
}

export default Birth
