import React from 'react';

const Preamble = () => {
  return (
    <div className='bg-gray-200 py-8 transition '>
         <div className='flex justify-center ietems-center pb-4 '>
        <h1 class="text-black lg:text-4xl border-b-4 lg:w-[200px] w-[120px] lg:py-4 py-1 border-black text-center text-3xl font-normal font-['URWImperialW01-Regular'] leading-[58px] ">Preamble</h1>
       
        
        </div>
      <div className=' mx-auto px-4 lg:px-8'>
        <div className='lg:mx-16 mx-auto bg-blue-50 shadow-sm rounded-lg overflow-hidden'>
          <div className='p-8 text-justify font-["URWImperialW01-Regular"]'>
            <p className='text-xl font-semibold text-black '>
             <span className='text-4xl italic'>Th</span>is story begins with the tale of a man whose life was like a shooting star, briefly lighting up the sky of his poor country's history.
            </p>
            <p className='text-xl text-gray-800 mt-4 '>
              He emerged at a time when his nation needed him most, leaving behind a legacy not just to protect his homeland but to contribute to global peace. This leader, much like unsung heroes throughout history, worked tirelessly for the betterment of his people and the world, without any expectation of reward or recognition.
            </p>
            <p className='text-xl  text-gray-800 mt-4'>
              This preamble seeks to introduce an the person who left behind an organization rooted in the true spirit of nationalism and defence. His legacies like the legacies of such leaders developed through their selfless dedication to their nation and the people transcend the boundaries of time and geography, their lives becoming beacons of inspiration for generations.
            </p>
            <p className='text-xl text-gray-800 mt-4'>
              The story of this man, emerging from a humble background to become an emblem of leadership and national spirit, is one such tale that deserves to be told and celebrated.
            </p>
            <p className='text-xl  text-gray-800 mt-4'>
              Born into modesty of pristine East Bengal rural setting, nothing in his early life hinted at the remarkable journey he was to embark upon. Yet, as he grew, it became apparent that he was no ordinary individual. He exhibited a natural flair for leadership, an innate ability to inspire and mobilize people towards a common goal.
            </p>
            <p className='text-xl text-gray-800 mt-4'>
              His leadership was not about titles or accolades but about making tangible, positive changes in the lives of the downtrodden. His approach was often seen as radical, a departure from the conventional and the comfortable. This perspective, coupled with his relentless pursuit of justice and equity, occasionally put him at odds with the established order.
            </p>
            <p className='text-xl  text-gray-800 mt-4'>
              His fearlessness and honesty of purpose were his armour against all adversities. In the face of opposition, he thrived, his spirit unbroken by the tumultuous waves of challenges that sought to derail him. The organization he left behind was a testament to his foresight and dedication.
            </p>
            <p className='text-xl text-gray-800 mt-4'>
              World War 2 put him to the testing ground of his survival skills through the muds and mountains of Eastern India to Akyab to the south in Burma. This setting most surreal atmosphere of life and death he embarked upon leading his men who were not trained through formal institution as fighting unit to fight the marauding invading enemy and marking them to form similar force for this own country once the war was over.
            </p>
            <p className='text-xl  text-gray-800 mt-4'>
              And with the war being over fell in foul hands of his superiors for some words he spoke for his own mother tongue. Completed his mission to setting the best of the best youths through a movement to recruit through motivation of removing fear for war and serving own nation. As of now the thriving Army of Bangladesh though remembers him in a way not to offend those who they think should be pleased for their so-called investment into the development with money and power.
            </p>
            <p className='text-xl text-gray-800 mt-4'>
              Yet, despite the monumental impact of his efforts, those reaping the benefits of his labour — the very beneficiaries of the organization and movement he created — often remain reluctant to fully acknowledge his contributions. There's a reticence, perhaps a fear that his towering achievements might overshadow their own. But true recognition does not diminish the light of others; instead, it illuminates the collective legacy of a movement and its people.
            </p>
            <p className='text-xl  text-gray-800 mt-4'>
              As we delve into the pages of this narrative, let us remember that this man's story is not just a recounting of past deeds but a living lesson in leadership, sacrifice, and visionary foresight. It's a call to recognize and honour those who have laid the foundations upon which societies stand and progress.
            </p>
            <p className='text-xl text-gray-800 mt-4'>
              His life is a reminder that true leaders do not merely navigate through calm waters but forge new paths amidst the highest waves, their eyes set on the horizon of a better future for all. His legacy is a challenge to every one of us: to rise above our circumstances, to lead with courage and integrity, and to commit ourselves to the tireless pursuit of equity and justice.
            </p>
            <p className='text-xl  text-gray-800 mt-4'>
              We are from the immediate generation of the legend who left the organization for us to benefit from. As his direct descendant, it is our responsibility to keep such examples to selflessness and dedication for the humanity alive through the generations that follow as it says in the gravestone, 'to inspire generations to come'. Hardly few are left of that time to recount his passion for serving the people of his country that is to be emulated.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preamble;
