import image1 from '../../assets/image/asiaghani/Amma at Kurmitola 1948.jpg';
import image2 from '../../assets/image/asiaghani/Begum Ghani Received at an EBRC Function.jpg';
import image3 from '../../assets/image/asiaghani/Begum Ghani attending a parade in EBRC.jpg';
import image4 from '../../assets/image/asiaghani/Begum Ghani distributing prizes in a Tiger reunion function to Mrs Nooruddin.jpg';
import image5 from '../../assets/image/asiaghani/Begum Ghani in EBRC function.jpg';
import image6 from '../../assets/image/asiaghani/Begum Ghani sharing a lamb roast with late Gen Sadeq at EBRC Dinner.jpg';
import image7 from '../../assets/image/asiaghani/Begum Ghani signing the visitor_s book in 33 Div.jpg';
import image8 from '../../assets/image/asiaghani/Begum Ghani with the PM Sheikh Hasina sharing snacks in an EBRC function.jpg';
import image9 from '../../assets/image/asiaghani/Mrs Ghani receiving The Tigers Brooch from Gen Musa C in C Pak Army 1st Tigers Reunion 6th Jan 1962.jpg';

import image12 from '../../assets/image/asiaghani/Mrs Jahangir helping Begum Ghani with the lamb roast in Tiger_s Dinner.jpg';
import image13 from '../../assets/image/asiaghani/a ghani with 8 eb .jpg';
import image14 from '../../assets/image/asiaghani/DSC01113.JPG';
import image15 from '../../assets/image/bmajor/15th Feb 1948 the historical photo to mark the innagural day of The 1st East Bengal Regiment.jpg';
import image16 from '../../assets/image/bmajor/Birth place of Late Major A Ghani.jpg';
import image17 from '../../assets/image/bmajor/Capt Ghani 1945.jpg';

import image18 from '../../assets/image/bmajor/Current structure of the grave after renovarton.jpg';

import image20 from '../../assets/image/bmajor/IMG-20180830-WA0001.jpg';
import image21 from '../../assets/image/bmajor/IMG-20191110-WA0006.jpg';
import image22 from '../../assets/image/bmajor/January 1962 - 1st reunion of the East Bengal Regiment.   Begum Asiya Ghani  & Khaled seated in the middle of the officers and ladies of the Regiment.jpg';
import image23 from '../../assets/image/bmajor/Memorail day at the grave in comilla cantt.jpg';
import image24 from '../../assets/image/bmajor/Original structure of the grave on a memorial day.jpg';
import image25 from '../../assets/image/bmajor/Photo of Maj GHANI on retirement-December 1953-01+01.jpg';
import image26 from '../../assets/image/bmajor/addressing public gathering in Shasidal in 1957.jpg';
import image27 from '../../assets/image/bmajor/dad at karachi 57-1.jpg';
import image28 from '../../assets/image/bmajor/dad at karachi 57-2.jpg';
import image29 from '../../assets/image/bmajor/dad at karachi 57-4.jpg';

import image30 from '../../assets/image/bmajor/dad at karachi 57.jpg';
import image31 from '../../assets/image/bmajor/dad at vaterns conf berlin 57.jpg';
import image32 from '../../assets/image/bmajor/ghani  patterson su khan 48.jpg';
import image33 from '../../assets/image/bmajor/karachi 57_2.jpg';
import image34 from '../../assets/image/bmajor/ltr dad to mama 57 from germany.jpg';
import image35 from '../../assets/image/bmajor/ltr dad to mama 57 from germany2.jpg';
import image36 from '../../assets/image/bmajor/memorail day comilla.jpeg';
import image37 from '../../assets/image/bmajor/original tiger.jpg';
import image38 from '../../assets/image/bmajor/remembering the founder_s name by naming the main  EBRC lounge.jpg';



const photoDetails = [
  { sl: 1, title: 'Amma at Kurmitola 1948', type: 'anchor', image: image1 },
  { sl: 2, title: 'Begum Ghani Received at an EBRC Function', type: 'anchor', image: image2 },
  { sl: 3, title: 'Begum Ghani attending a parade in EBRC', type: 'anchor', image: image3 },
  { sl: 4, title: 'Begum Ghani distributing prizes in a Tiger reunion function to Mrs Nooruddin', type: 'anchor', image: image4 },
  { sl: 5, title: 'Begum Ghani in EBRC function.', type: 'anchor', image: image5 },
  { sl: 6, title: 'Begum Ghani sharing a lamb roast with late Gen Sadeq at EBRC Dinner', type: 'anchor', image: image6 },
  { sl: 7, title: "Begum Ghani signing the visitor's book in 33 Div", type: 'anchor', image: image7 },
  { sl: 8, title: 'Begum Ghani with the PM Sheikh Hasina sharing snacks in an EBRC function.', type: 'anchor', image: image8 },
  { sl: 9, title: 'Mrs Ghani receiving The Tigers Brooch from Gen Musa C in C Pak Army 1st Tigers Reunion 6th Jan 1962', type: 'anchor', image: image9 },
 
  { sl: 12, title: 'Mrs Jahangir helping Begum Ghani with the lamb roast in Tiger_s Dinner.jpg', type: 'anchor', image: image12 },
  { sl: 13, title: 'A ghani with 8 eb ', type: 'anchor', image: image13 },
  { sl: 14, title: 'Death day of Asiya Begum', type: 'anchor', image: image14 },
  { sl: 15, title: '15th Feb 1948 the historical photo to mark the innagural day of The 1st East Bengal Regiment.jpg', type: 'major', image: image15 },
  { sl: 16, title: 'Birth place of Late Major A Ghani', type: 'major', image: image16 },
  { sl: 17, title: 'Capt Ghani 1945', type: 'major', image: image17 },
  { sl: 18, title: 'Current structure of the grave after renovarton', type: 'major', image: image18 },
  { sl: 20, title: 'About  legend', type: 'major', image: image20 },
  { sl: 21, title: 'About legend', type: 'major', image: image21 },
  { sl: 22, title: 'January 1962 - 1st reunion of the East Bengal Regiment.   Begum Asiya Ghani  & Khaled seated in the middle of the officers and ladies of the Regiment', type: 'major', image: image22 },
  { sl: 23, title: 'Memorail day at the grave in comilla cantt', type: 'major', image: image23 },
  { sl: 24, title: 'Original structure of the grave on a memorial day', type: 'major', image: image24 },
  { sl: 25, title: 'Photo of Maj GHANI on retirement-December 1953-01+01', type: 'major', image: image25 },
  { sl: 26, title: 'addressing public gathering in Shasidal in 1957', type: 'major', image: image26 },
  { sl: 27, title: 'dad at karachi 57. Odit.', type: 'major', image: image27 },
  { sl: 28, title: 'dad at karachi 57-1.', type: 'major', image: image28 },
  { sl: 29, title: 'dad at karachi 57.', type: 'major', image: image29 },
  { sl: 30, title: 'dad at karachi 57', type: 'major', image: image30 },
  { sl: 31, title: 'dad at vaterns conf berlin 57', type: 'major', image: image31 },
  { sl: 32, title: 'ghani  patterson su khan 48', type: 'major', image: image32 },
  { sl: 33, title: 'karachi 57', type: 'major', image: image33 },
  { sl: 34, title: 'ltr dad to mama 57 from germany', type: 'major', image: image34 },
  { sl: 35, title: 'ltr dad to mama 57 from germany', type: 'major', image: image35 },
  { sl: 36, title: 'memorail day comilla.', type: 'major', image: image36 },
  { sl: 37, title: 'Original tiger', type: 'major', image: image37 },
  { sl: 38, title: 'remembering the founder_s name by naming the main  EBRC lounge.', type: 'major', image: image38 },
 
];


export default photoDetails