import React, { useState } from 'react';
import AnchorDetails from './AnchorDetails.jsx';


import SingleAncor from './SingleAncor.jsx';

const Legachy = () => {
  const [items, setItems] = useState(AnchorDetails);



  return (
    <div className="py-[70px]">
 <div className="w-full h-px bg-zinc-800"></div>
      <div className="py-12 bg-majorbg">
        <h1 className="text-center text-white text-[40px] font-normal font-['URWImperialW01-Regular'] capitalize leading-[50px]">Anchor : The Wife</h1>
      </div>

     

      {items.map((info, index) => {
  const { sl, title, image,  desc,linkto } = info;
  const formattedDesc = desc.replace(/<br>/g, '</p><p class="mt-3">');

  return (
    <div key={sl}>
      <SingleAncor title={title} linkto={linkto} image={image} desc={formattedDesc} sl={sl} index={index} />
    </div>
  );
})}

      {/* Popup */}
    </div>
  );
};

export default Legachy;
