import React from 'react'
import HomesliderCard from './HomesliderCard'
import HomeCard from './HomeCard'
import SpeecheContainer from '../Speeches/SpeecheContainter'
import Preamble from '../preamble/preamble'


const Home = () => {
  return (
    <div>
<div className=''>
<HomesliderCard/>
</div>
<Preamble/>
     <HomeCard/>
   
     <SpeecheContainer/>

    </div>
  )
}

export default Home
