import image1 from '../../assets/image/3.jpg'
import image2 from '../../assets/image/23.jpg'
import image3 from '../../assets/image/1.jpg'


const AnchorDetails =[
    {
        sl:1,
      
        image:image1,
        type:"ancor",
      
        desc:"Much is not known of the lady who so young joined her husband in 1946 in an unknown journey – the journey which will eventually lead them to the commitment to organise a force to be reckoned with l Aganinst the wishes of her parents and relaives she embarked on to the journey to an unknown frontier. From her diary one can understand the commitment and elation to be with a person who was embarking in a very difficult mission. In the sojourn she had the chance of seeing the struggle of individual soldier and all that went to convince a hoard of soldiers who had bet their live and future in the hands of an individual. Begum Ghani’s confidence in her husband was unflinching. She was full of conviction that what ever he undertook he never failed. She would talk to us about the way she left Dhaka way back in 1946 from the house of Mr HH Nomani. She was daughter to the family and did not want spare her to join her husband. She was the only lady who would be with the whole group and how would that look. She did not falter a little and was full of confidence that she will be taken care of. Due the association of Maj Ghani with high civil officials he was offered job in the government establishment as Director of Procurement of East Bengal. It would end his very seshaky military career as his commission was Emergency commission and any time the government could terminate it; but Major Ghani was deeply committed to his men who fought with him during the whole campaign and had put their lives at stake for him and he for them so they seemed inseparable. So be it Amma journeyed for days to reach Jalna a cantonment in Indian Maharashtra state."
    },
    {
        sl:2,
     
        image:image2,
        type:"anchor",
    
        desc: "Well the story is very inereestiong to read or hear from her and I persuaded her to put so in black and white what ever she rememberd of that time and how things went hayway after the regiment was formed. The dream shagttered due to the conspiracy of some peers and the Pakisani authorities as his blatant out bursts against the ill doings did not impress them much. Besides, he was not a very active member of the Officr’s mess whose evenings were filled with drunken orgies and Ghani never took a drop of alcohol and even the foreign officers hesitated to open a bottle of beer in front of him. All these may appear to be fiction to some uniniatiated one but the offivers and men who served with with him and under him though for a very short spell of time recounted to me when they got time to speak of my father. The fact that he was promoted as local major on 15th February 1948 in the parade for the achievement that he accomplished. The only matter that put his service at stake was the protest before the crowd that he wont speak nor his soldiers speak urdu was the key issue. The Pak authority did not take any immediated action to put and end to this open rebellion but was waiting for their turn to put his career at stake. His appeared in a commission review board in May 1948 where the General recommended him unsuitable to serve in any commissioned rank in the Army. Well no one knew that and he did not communicate it to even his wife lest it would be harmful to the pregnant wife. The event blew up when the posting order came to move to Dinajpur as adjutant of PNG. Obviously he in the meantime was upgraed to Temporary Major would lose it" ,
    },
    {
        sl:3,
   
        image:image3,
        type:"anchor",
  
        desc: "It ignited the fire among his troops who were his band of brothers. They came out of their barracks an protested that if the government did to rescind the order they would leave the army and go where he went. It opened the eyes of the military hierarchy that such a person if even let out o the Army could be a potential threat to the government. It was an open insubordination and can only be treated next to treason. Well Major Ghani consoled them that the regiment is not his or his father’s property and that no one except the government owns it. So back to your barrack and we will have chance to serve together again. But it did not happen. He had to leave unit with all his soldiers in tears and my mother pregnant for months had to leave Kurmitola and go home for the delivery of the child. The picture you find her sitting Infront of the hut was built by Major Ghani with his own money so that he could be always with the troops. I shall be covering my mother’s tale separately as a part of a book. She was the wife of a real warrior who could deny that"
    },
    
]

export default AnchorDetails