import image1 from '../../assets/image/thumnail1.PNG';
import image2 from '../../assets/image/2.jpg';
import image3 from '../../assets/image/thumnail1.PNG';
import image4 from '../../assets/image/4.jpg';
import image5 from '../../assets/image/thumnail1.PNG';
import image6 from '../../assets/image/6.jpg';
import image7 from '../../assets/image/7.jpg';
import image8 from '../../assets/image/thumnail1.PNG';
import image9 from '../../assets/image/9.jpg';



const EventDetails = [
  { sl: 1, title: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odit.',link:"https://youtu.be/s4ZgU_u6PKc", type: 'video', image: image1 },
  { sl: 2, title: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odit.', type: 'photo', image: image2 },
  { sl: 3, title: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odit.',link:"https://youtu.be/s4ZgU_u6PKc", type: 'video', image: image3 },
  { sl: 4, title: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odit.', type: 'photo', image: image4 },
  { sl: 5, title: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odit.', type: 'video',link:"https://youtu.be/s4ZgU_u6PKc", image: image5 },
  { sl: 6, title: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odit.', type: 'photo', image: image6 },
  { sl: 7, title: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odit.', type: 'photo', image: image7 },
  { sl: 8, title: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odit.',link:"https://youtu.be/s4ZgU_u6PKc", type: 'video', image: image8 },
  { sl: 9, title: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odit.', type: 'photo', image: image9 },
 
  
];


export default EventDetails